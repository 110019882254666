<template>
  <div
    v-on-click-outside="onClickOutsideHandler"
    class="relative flex flex-col flex-1 space-y-1"
    :class="[disabled ? 'bg-gray-50' : 'bg-white']"
    data-cy="stip-dropdown"
  >
    <div class="relative">
      <input
        :value="stipText"
        @input="updateStipText"
        data-cy="file-type-dd"
        class="relative w-full pl-4 pr-8 h-10 text-left text-sm rounded py-1 flex items-center focus:ring-0"
        :class="[
          dropdownOpen
            ? 'border-blue-500 hover:border-blue-500 border-2'
            : 'border border-gray-200',
          disabled ? 'text-gray-400' : 'text-headline'
        ]"
        :disabled="disabled"
        :placeholder="placeholderText"
        @click.stop="dropdownOpen = true"
      />
      <div
        class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
      >
        <icon-base
          v-if="!dropdownOpen"
          height="6"
          width="10"
          :icon="IconDropdownArrow"
          color="#D4D8E2"
          @click.stop="dropdownOpen = true"
        />
        <div v-else>
          <icon-base
            v-if="!stipText"
            width="19"
            height="18"
            :icon="IconSearch"
          />
          <icon-x v-else @click="stipText = ''" />
        </div>
      </div>
    </div>
    <field-error-message :value="fieldError" />
    <div
      v-if="dropdownOpen"
      class="absolute left-0 top-full bg-white z-100 h-50 overflow-y-auto shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none w-full"
      :class="dropdownTeleported ? 'max-w-50-screen' : 'w-full'"
      ref="dropdownWrapper"
      data-cy="stip-dropdown-menu"
    >
      <div>
        <div v-for="(group, index) in filteredGroups" :key="group.id">
          <div
            class="font-bold text-headline px-4 py-2 border-y"
            :class="{ 'my-2': index > 0 }"
          >
            {{ group.name }}
          </div>
          <div class="pl-5 flex flex-col">
            <div
              v-for="category in getFilteredCategories(group)"
              :key="category.type"
              :data-cy="`category-${category.name}`"
              class="flex my-2 flex-col cursor-pointer w-max"
              @click="selectCategory(category)"
            >
              {{ category.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { getStipNameByType } from "@/helpers/common";
import { useStipsCollection } from "@/hooks/stips";
import { useField } from "vee-validate";
import { useTeleportV2 } from "@/hooks/elements";
import { useStore } from "vuex";
import FieldErrorMessage from "@/components/ui/inputs/FieldErrorMessage.vue";
import IconBase from "@/components/ui/IconBase.vue";
import type { DocumentType, StipsCollection } from "@/models/common";
import type { PropType } from "vue";
import type { IStip } from "@/models/applications";
import { useI18n } from "vue-i18n";
import IconX from "@/components/icons/deals/IconX.vue";
import IconDropdownArrow from "@/components/icons/IconDropdownArrow.vue";
import IconSearch from "@/components/icons/IconSearch.vue";

const emit = defineEmits<{
  (eventName: "change", option: string | number): void;
  (e: "update:modelValue", value: string): void;
}>();

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  model: {
    type: Object as PropType<IStip | undefined>
  },
  placeholder: {
    type: String,
    default: "Select file type"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: "stip-dropdown-category"
  },
  dropdownTeleported: {
    type: Boolean,
    default: false
  }
});

const stipCollection = useStipsCollection();
const store = useStore();
const { t } = useI18n();

const { errorMessage: fieldError, handleChange } = useField(
  props.name,
  undefined,
  {
    initialValue: props.modelValue
  }
);

const stipText = ref("");
const dropdownOpen = ref(false);
const dropdownWrapper = ref<HTMLDivElement | null>(null);
const searchInputRef = ref<HTMLDivElement | null>(null);

const filteredGroups = computed(() => {
  if (!stipText.value) {
    return stipCollection.value;
  }
  const searchTextLower = stipText.value.toLowerCase();
  return stipCollection.value.filter((group) => {
    return (
      group.name.toLowerCase().includes(searchTextLower) ||
      getFilteredCategories(group).length > 0
    );
  });
});

const placeholderText = computed(() =>
  dropdownOpen.value
    ? `${t("COMMON.SEARCH")} ${t("STIPS.FILE_TYPE")}`
    : props.placeholder
);

const selectCategory = async (category: DocumentType) => {
  dropdownOpen.value = false;
  handleChange(category.type);
  emit("change", category.type);
  emit("update:modelValue", category.type);
  if (props.model) {
    await store.dispatch("stips/updateStipFileType", {
      id: props.model.id,
      file_type: category.type
    });
  }
  stipText.value = getStipNameByType(props.modelValue, stipCollection.value);
};

const getFilteredCategories = (group: StipsCollection) => {
  if (!stipText.value) {
    return group.categories;
  }
  const searchTextLower = stipText.value.toLowerCase();
  return group.categories.filter((category: DocumentType) => {
    return (
      category.name.toLowerCase().includes(searchTextLower) ||
      group.name.toLowerCase().includes(searchTextLower)
    );
  });
};

if (props.dropdownTeleported) {
  useTeleportV2({
    target: dropdownWrapper,
    to: "#menus",
    offset: { top: 45 }
  });
}

const onClickOutsideHandler = [
  () => {
    dropdownOpen.value = false;
    stipText.value = getStipNameByType(props.modelValue, stipCollection.value);
  },
  { ignore: [searchInputRef] }
];

const updateStipText = (e: Event) => {
  const target = e.target as HTMLInputElement;
  stipText.value = target.value;
};

watch(
  () => props.modelValue,
  (newValue) => {
    stipText.value = getStipNameByType(newValue, stipCollection.value);
  },
  { immediate: true }
);
</script>
